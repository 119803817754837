/* eslint-disable no-param-reassign, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment */
import {Provider, Serializer, SignerInterface, utils, interfaces} from "koilib";
import {AuthenticationData} from "./Interfaces";

export class WebAuthNSigner implements SignerInterface {
    provider?: Provider;
    authenticationData: AuthenticationData;
    address: string;
    private sendOptions: any;

    constructor(c: {
        provider?: Provider;
        address: string;
        authenticationData: AuthenticationData
    }) {
        this.provider = c.provider;
        this.address = c.address;
        this.authenticationData = c.authenticationData;
    }

    getAddress(compressed = true) : string {
        return this.address;
    }

    async signTransaction(
        transaction: interfaces.TransactionJson | interfaces.TransactionJsonWait,
        abis?: Record<string, interfaces.Abi>
    ): Promise<interfaces.TransactionJson> {
        if (!transaction.id) throw new Error("Missing transaction id");


        const descriptorJson = {
            nested: {
                authenticatorData: {
                    fields: {
                        credential_id: {
                            type: "string",
                            id: 1
                        },
                        signature: {
                            type: "bytes",
                            id: 2
                        },
                        authenticator_data: {
                            type: "bytes",
                            id: 3
                        },
                        client_data: {
                            type: "bytes",
                            id: 4
                        }
                    }
                },
            },
        };

        const serializer1 = new Serializer(descriptorJson);
        const signature = await serializer1.serialize({
            credential_id: this.authenticationData.credential_id,
            signature: this.authenticationData.signature,
            authenticator_data: this.authenticationData.authenticator_data,
            client_data: this.authenticationData.client_data
        }, "authenticatorData");
        if (!transaction.signatures) transaction.signatures = [];
        transaction.signatures.push(utils.encodeBase64url(signature));

        return transaction;
    }

    async sendTransaction(
        transaction: interfaces.TransactionJson | interfaces.TransactionJsonWait,
        options?: interfaces.SendTransactionOptions
    ): Promise<{
        receipt: interfaces.TransactionReceipt;
        transaction: interfaces.TransactionJsonWait;
    }> {
        if (!transaction.signatures || !transaction.signatures?.length)
            transaction = await this.signTransaction(
                transaction,
                options?.sendAbis ? options.abis : undefined
            );
        if (!this.provider) throw new Error("provider is undefined");
        const opts: interfaces.SendTransactionOptions = {
            ...this.sendOptions,
            ...options,
        };
        if (opts.beforeSend) {
            await opts.beforeSend(transaction, options);
        }


        console.log(transaction)


        return this.provider.sendTransaction(transaction, opts.broadcast);
    }

    signBlock(block: interfaces.BlockJson) : Promise<interfaces.BlockJson> {
        return new Promise(resolve => {});
    }

    getPrivateKey(format: "wif" | "hex", compressed?: boolean) : string {
        return ""
    }

    signHash(hash: Uint8Array) : Promise<Uint8Array> {
        return new Promise(resolve => {});
    }

    signMessage(message: string | Uint8Array) : Promise<Uint8Array> {
        return new Promise(resolve => {});
    }

    // Transaction
    prepareTransaction(tx: interfaces.TransactionJson) : Promise<interfaces.TransactionJson> {
        return new Promise(resolve => {});
    }

    // Block
    prepareBlock(block: interfaces.BlockJson) : Promise<interfaces.BlockJson> {
        return new Promise(resolve => {});
    }


}

export default WebAuthNSigner;
