import React, {useEffect, useState} from 'react';
import './App.css';
import {utils as pene} from '@passwordless-id/webauthn'
import {FREE_MANA_CONTRACT_ID, KOIN_CONTRACT_ID, NICKNAMES_CONTRACT_ID, RPC_NODE} from "./utils/Constants";
import {Contract, Provider, Signer, utils} from "koilib";
import WebAuthNSigner from "./utils/WebAuthNSigner";
import {AuthenticationData} from "./utils/Interfaces";
import {TransactionOptions} from "koilib/lib/interface";

function App() {
    const [available, setAvailable] = useState(false);
    const [address, setAddress] = useState("1AaUbDsUnkHghcNuQJhPUuWtL2uboDQzrs");
    const [nickname, setNickname] = useState("");
    const [myaddress, setMyaddress] = useState("");
    const [logs, setLogs] = useState<Array<string>>([]);

    const log = (line: string) => {
        setLogs(oldarray => [...oldarray, line]);
        console.log(line);
    }

    const getAuthentication = async (): Promise<AuthenticationData> => {
        let authOptions :PublicKeyCredentialRequestOptions = {
            challenge: pene.parseBase64url("randomstring"),
            rpId: 'keepoke.com',
            allowCredentials: [],
            userVerification: "required",
            timeout: 60000,
        }
    
        let auth = await navigator.credentials.get({publicKey: authOptions}) as PublicKeyCredential
        console.log(auth)
        //log("getAuthentication - auth: " + JSON.stringify(auth)); -- stampa {}
    
        const response = auth.response as AuthenticatorAssertionResponse
        console.log(response)
        //log("getAuthentication - response: " + JSON.stringify(response)); -- stampa {}
        
        const authentication : AuthenticationData = {
            credential_id: auth.id,
            authenticator_data: pene.toBase64url(response.authenticatorData),
            client_data: pene.toBase64url(response.clientDataJSON),
            signature: pene.toBase64url(response.signature),
        }
    
        return authentication
    }

    /*
    const getAuthenticationOLD = async (): Promise<AuthenticationData> => {
        const challenge = "randomstring"
        const authentication = await client.authenticate([], challenge, {
            "authenticatorType": "auto",
            "userVerification": "required",
            "timeout": 60000
        });
        console.log('authentication', authentication);
        return {
            credential_id: authentication.credentialId,
            signature: authentication.signature,
            authenticator_data: authentication.authenticatorData,
            client_data: authentication.clientData
        }
    }
    */

    const getAddressFromNickname = async () => {
        const provider = new Provider([RPC_NODE]);
        const contract = new Contract({
            id: NICKNAMES_CONTRACT_ID,
            provider: provider
        });
        contract.abi = await contract.fetchAbi();

        const {result } = await contract.functions.owner_of({
            token_id: '0x' + utils.toHexString(new TextEncoder().encode(nickname))
        });

        log("getAddressFromNickname - result: " + JSON.stringify(result));

        if (result) {
            setMyaddress(result.account);
        }
    }

    const transfer = async () => {

        try {
            const authenticationData = await getAuthentication();
    
            const provider = new Provider([RPC_NODE]);
            const signer = new WebAuthNSigner({address: myaddress!, authenticationData});
            signer.provider = provider;
    
            const contract = new Contract({
                id: KOIN_CONTRACT_ID,
                signer: signer as unknown as Signer,
                abi: utils.tokenAbi
            });

            const rcLimit = "2000000000";
            let txOptions: TransactionOptions;
            txOptions = {
                payer: FREE_MANA_CONTRACT_ID,
                rcLimit,
            };

    
            const {transaction, result, receipt, operation} = await contract.functions.transfer({
                from: signer.getAddress(),
                to: address,
                value: "100000000"
            }, txOptions);

            log("transfer - transaction: " + JSON.stringify(transaction));
            log("transfer - result: " + JSON.stringify(result));
            log("transfer - receipt: " + JSON.stringify(receipt));
            log("transfer - operation: " + JSON.stringify(operation));
        } catch (e) {
            console.log(e)
            // @ts-ignore
            log("transfer - error: " + e.toString());
        }
    }


    useEffect(() => {
        setAvailable(!!window.PublicKeyCredential);
    }, [])

    return (
        <div className="App">
            {available &&
                <p>client available</p>
            }

            {!available &&
                <p>client not available</p>
            }

            <label>Your Nickname 2</label>
            <input type="text" value={nickname} onChange={(e) => setNickname(e.target.value)}/>
            <br/>
            <button onClick={getAddressFromNickname}>get address</button>
            <br/>
            {myaddress}
            <br/>
            <br/>

            <label>Address</label>
            <input type="text" value={address} onChange={(e) => setAddress(e.target.value)}/>
            <br/>
            <button onClick={transfer} disabled={myaddress === ""}>transfer 1 tkoin</button>

            {logs.map((value, index) => {
                return <p key={index}>{value}</p>
            })}

            <a href={"keepoke://account?address=123456789"}>Back to app</a>
        </div>
    );
}

export default App;
